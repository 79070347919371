import { Field } from "@progress/kendo-react-form";
import { StackLayout } from "@progress/kendo-react-layout";

import { FormDropDownList, FormInput, FormMultiSelect } from "../FormComponents";
import { requiredValidator } from "../validators";

const prefaceQuestionnaire: string = "<h2 style=\"text-align: center;\"><strong>Referral Questionnaire<\/strong><\/h2>" +
    "Community Veterinary Outreach's program provides preventative veterinary care only.<br/><br/>Pet owners should NOT wait for a clinic if they have a sick or injured pet.They should be strongly advised to seek veterinary care immediately.<br/><br/>" +
    "Referred clients must meet all of the following criteria:<br/><br/>" +
    "1. Client is homeless, transitionally-housed or vulnerably-housed (CHRN definition of homeless)<br/>" +
    "2. Client is receiving ODSP, OW, or has no income; or if the client has no ID - is making regular use of shelter or agency services<br/>" +
    "3. Client does NOT have an existing veterinarian - client - patient relationship(VPCR), defined as having their animal examined or treated by a veterinarian in the past 12 months.<br/><br/>" +
    "All information collected is strictly confidential and during analysis of data all personal and/or identifying information will be stripped and destroyed. This questionnaire is voluntary and veterinary services will not be withheld if any or all of the questions, other than essential client and pet information needed by law, are not answered.<br/>" +
    "Answering questions regarding pet bites will not result in a legal complaint by Community Veterinary Outreach.<br/><br/>" +
    "If there are any questions while completing this form, please e-mail info@vetoutreach.org for further direction.";

const ExamNewQuestionnaire = () => {
    return (
        <div className={"sds-questionnaire"}>
            <span dangerouslySetInnerHTML={{ __html: prefaceQuestionnaire }} />

            <fieldset
                style={{}}
                className={"k-form-fieldset"}
            >
                <legend
                    style={{ paddingTop: "24px" }}
                    className={"k-form-legend"}>
                    Client Information
                </legend>

                <StackLayout gap={48}>
                    <Field
                        label={"With which gender do you identify?*"}
                        name={"A169"}
                        component={FormDropDownList}
                        data={["N/A", "Male", "Female", "Transgender", "Non-binary", "Not listed", "Prefer not to Disclose"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N169"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Preferred Personal Pronouns"}
                        name={"A170"}
                        component={FormMultiSelect}
                        autoClose={false}
                        header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                        data={["He/Him/His", "She/Her/Hers", "They/Them/Theirs", "Ze/Hir", "Not Listed"]}
                    />
                    <Field
                        label={"\""}
                        name={"N170"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48} >
                    <Field
                        label={"Client Age (years)"}
                        name={"A168"}
                        component={FormInput}
                    />
                    <Field
                        label={"\""}
                        name={"N168"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Income Source"}
                        name={"A171"}
                        component={FormMultiSelect}
                        autoClose={false}
                        header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                        data={["Income support or assistance", "Disability support", "Personal needs allowance",
                            "Child and family benefits", "Seniors Supplement", "Employment Income", "Other (Please Describe)"]}
                    />
                    <Field
                        label={"\""}
                        name={"N171"}
                        component={FormInput}
                    />
                </StackLayout>
                <Field
                    label={"Total Monthly Household Income"}
                    name={"A172"}
                    component={FormInput}
                />
                <StackLayout gap={48}>
                    <Field
                        label={"Marital Status*"}
                        name={"A173"}
                        component={FormDropDownList}
                        data={["N/A", "Single", "Married", "Common-law", "Separated", "Divorced", "Widow/Widower", "Other (Text Box)"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"\""}
                        name={"N173"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Number of dependents in household"}
                        name={"A174"}
                        component={FormInput}
                        hint={"Dependents are defined as 1) persons under 22 years of age who do not have a spouse or partner OR " +
                            "2) those over 22 years of age who depend on their parents since before aged 22 and who cannot support " +
                            "themselves because of a mental or physical condition"}
                    />
                    <Field
                        label={"\""}
                        name={"N174"}
                        component={FormInput}
                    />
                </StackLayout>
                <Field
                    label={"Client’s current housing status*"}
                    name={"A175"}
                    component={FormMultiSelect}
                    autoClose={false}
                    header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                    data={["Unsheltered", "Living in public or private spaces without consent or contract", "Living in places not intended for permanent human habitation",
                        "Emergency sheltered - living in emergency shelters e.g. homeless, domestic/family violence, natural disaster/fire)",
                        "Provisionally accommodated", "Living in interim/transitional housing", "Living temporarily with others (couch surfing, hidden homeless)",
                        "Living in short term, temporary rental accommodations", "Living in institutional care without permanent housing (penal institution, medical/mental health institution, residential treatment or withdrawal management centres, childrens institution/group home)",
                        "Living in accommodation/reception centres for recently arrived immigrants and refugees",
                        "At risk of homelessness", "Precarious employment", "Sudden unemployment", "Loss of supports", "Facing eviction",
                        "Severe and/or persistent mental illness and/or active substance use", "Domestic/family violence", "Inadequate/unsuited institutional care"]}
                    validator={requiredValidator}
                />
                <legend
                    style={{ paddingTop: "24px" }}
                    className={"k-form-legend"}>
                    Pet Ownership Information
                </legend>
                <StackLayout gap={48}>
                    <Field
                        label={"Have you had any difficulty seeking shelter or housing because you have a pet in the past year?*"}
                        name={"A176"}
                        component={FormDropDownList}
                        data={["N/A", "Yes", "No"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N176"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Do you feel that having a pet has influenced your own health?*"}
                        name={"A177"}
                        component={FormDropDownList}
                        data={["N/A", "Yes", "No", "I don't know"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"\""}
                        name={"N177"}
                        component={FormInput}
                    />
                </StackLayout>
                <Field
                    label={"If YES, please tell us in what way"}
                    name={"A178"}
                    component={FormInput}
                />
                <StackLayout gap={48}>
                    <Field
                        label={"I need support arranging transportation to/from the clinic.*"}
                        name={"A179"}
                        component={FormDropDownList}
                        data={["Yes", "No", "N/A"]}
                        hint={"Not always available at every clinic, but we will notify you when we call to schedule your appointment"}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"\""}
                        name={"N179"}
                        component={FormInput}
                    />
                </StackLayout>
                <div
                    style={{ paddingTop: "24px", fontWeight: "bold" }}>
                    We are dedicated to improving the health of both people and pets, and may have human healthcare and social support services available at veterinary outreach clinics. To help us understand how we can best support you, please answer the following questions
                </div>
                <StackLayout gap={48}>
                    <Field
                        label={"Have you smoked cigarettes in the past 30 days?*"}
                        name={"A180"}
                        component={FormDropDownList}
                        data={["Yes", "No"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N180"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Do you smoke cigarettes every day, occasionally or not at all?*"}
                        name={"A181"}
                        component={FormDropDownList}
                        data={["N/A", "Daily", "Occasionally", "Don't know/prefer not to say", "Not at all"]}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"\""}
                        name={"N181"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"Where do you usually go when you need immediate care for a minor health problem?"}
                        name={"A182"}
                        component={FormMultiSelect}
                        autoClose={false}
                        header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                        data={["A doctors office", "A hospital outpatient clinic", "A community health centre (or CLSC)", "A walk-in clinic",
                            "A hospital emergency room", "Other (Please Describe)", "Outreach nurse", "Other social service setting (e.g. CMHA)",
                            "CVO One Health Clinic", "Don't know/prefer not to say"]}
                    />
                    <Field
                        label={"\""}
                        name={"N182"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48}>
                    <Field
                        label={"What community supports would you like to receive?"}
                        name={"A183"}
                        component={FormMultiSelect}
                        autoClose={false}
                        header={<span style={{ float: "right", paddingRight: "32px", fontStyle: "italic" }}>Click here when done</span>}
                        data={["Smoking Cessation Services", "Healthy Eating / Active Living Services", "Flu Vaccination", "Dental Screening",
                            "Housing support", "Primary health care", "Mental health support", "Addictions support",
                            "Substance use support", "COVID information", "Other (Please Describe)"]}
                    />
                    <Field
                        label={"\""}
                        name={"N183"}
                        component={FormInput}
                    />
                </StackLayout>
                <StackLayout gap={48} style={{ paddingBottom: "24px" }}>
                    <Field
                        label={"Do you identify as a visible minority or as a member of a racialized community?*"}
                        name={"A184"}
                        component={FormDropDownList}
                        data={["Yes", "No", "N/A"]}
                        hint={"If yes, please describe in notes."}
                        validator={requiredValidator}
                    />
                    <Field
                        label={"Notes"}
                        name={"N184"}
                        component={FormInput}
                    />
                </StackLayout>
            </fieldset>
        </div>
    );
}

export default ExamNewQuestionnaire;
